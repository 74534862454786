import jwtdecode from 'jwt-decode';

import { isDecodableJWT, SFJWTPayLoad } from '../../helpers/JWT';
import { IUser } from '../../user/IUser';
import { Persistence } from '../../persistence/Persistence';
import { getHash } from '../../helpers/CryptoHelper';

function getHashValue(key: string, from: string): string {
  const matches = from.match(new RegExp(key + '=([^&]*)'));
  /* istanbul ignore next: No need to codecov */
  return matches ? matches[1] : '';
}

function getSearchValue(key: string, from: string): string | null {
  const matches = from.match(new RegExp(key + '=([^&]*)'));
  /* istanbul ignore next: No need to codecov */
  return matches ? matches[1] : null;
}

function getTokenFromHash(tokenKey: string, hash: string): string | null {
  return getHashValue(tokenKey, hash);
}

function getAuthCodeFromURL(url: Location): string | null {
  const code = getSearchValue('code', url.search);
  return code && code?.length > 0 ? code : null;
}

export enum OAuth2GrantCallbackMode {
  Unsupported = 0,
  ImplicitFlow = 1 << 0, // 0001 = 1
  ImplicitFlowHybrid = 1 << 1, // 0010 = 2
  AuthenticationCode = 1 << 2, // 0100 = 4
  AuthenticationCodeHybrid = 1 << 3, // 1000 = 8
  CipFlow = 1 << 4, // 10000 = 16
  ImplicitFlowAuthenticationCodeHybrid = ImplicitFlowHybrid | AuthenticationCodeHybrid, // 1010 = 10
}

/**
 * Checks the url-hash for the id_token and access_token params.
 * @returns true if has both id_token and access_token
 */
function hasBeenCalledBackFromOAuthServerImplicitFlow(url: Location): boolean {
  if (url === undefined || url.hash === undefined || url.hash === '') {
    return false;
  }

  const returnedIdToken = getTokenFromHash('id_token', url.hash);
  const returnedAccesshToken = getTokenFromHash('access_token', url.hash);

  return returnedIdToken !== '' && returnedAccesshToken !== '';
}

/**
 * Checks the url-search for the code param.
 * @returns true if has code is param
 */
function hasBeenCalledBackFromOAuthServerWebServerFlow(url: Location): boolean {
  if (url === undefined || url.search === undefined || url.search === '') return false;
  return url.search.includes('code=');
}

function isCipAuthCallback(url: Location): boolean {
  if (url.pathname === '/auth/callback') {
    const params = new URL(url.toString()).searchParams;
    const code = params.get('code');
    const state = params.get('state');
    if (code && state) {
      return true;
    }
  }

  return false;
}

/**
 * Checks the url for callback mode
 * @returns true if has code is param
 */
export function getOAuth2CallbackMode(location: Location, hyrbridMode: boolean): OAuth2GrantCallbackMode {
  if (isCipAuthCallback(location)) {
    return OAuth2GrantCallbackMode.CipFlow;
  } else if (hasBeenCalledBackFromOAuthServerImplicitFlow(location)) {
    return !hyrbridMode ? OAuth2GrantCallbackMode.ImplicitFlow : OAuth2GrantCallbackMode.ImplicitFlowHybrid;
  } else if (hasBeenCalledBackFromOAuthServerWebServerFlow(location)) {
    return !hyrbridMode ? OAuth2GrantCallbackMode.AuthenticationCode : OAuth2GrantCallbackMode.AuthenticationCodeHybrid;
  }
  return OAuth2GrantCallbackMode.Unsupported;
}

export type AuthenticationCodeCallbackResult = {
  code?: string;
  error?: string;
};

/**
 *
 * Checks if the call back has a code
 * @returns code
 */
export function authenticationCodeCallbackResult(location: Location): AuthenticationCodeCallbackResult {
  const code = getAuthCodeFromURL(location);
  if (!code) {
    return { error: 'Missing auth code' };
  }
  return { code: code };
}

export type ImplictFlowCallbackResult = {
  idToken?: string;
  accessToken?: string;
  error?: string;
};

/**
 *
 * Checks if the call back has a id_token and access_token
 * @returns tuple [id_token, access_token]
 */
export async function implicitFlowCallbackResult(location: Location): Promise<ImplictFlowCallbackResult> {
  try {
    const returnedIdToken = getTokenFromHash('id_token', location.hash);
    const returnedAccessToken = getTokenFromHash('access_token', location.hash);
    const hasValidIdToken = isDecodableJWT(returnedIdToken!);

    if (!returnedAccessToken) {
      return { error: 'Invalid access token' };
    }

    if (!hasValidIdToken) {
      return { error: 'Invalid id token' };
    }

    const accessToken = returnedAccessToken!;
    const idToken = returnedIdToken!;

    return { idToken: idToken, accessToken: accessToken };
  } catch (error) {
    return { error: `Implicit Flow callback error: ${error}` };
  }
}

/** Gets user Id from either SF or CIP token. */
function getUserIdFromSFToken(decodedToken: SFJWTPayLoad) {
  const subParts: string[] = decodedToken.sub.split('/');
  // If the Sub attribute = https://test.salesforce.com/id/00D260000001DObEAM/00526000005TbDtAAK,
  // the user ID is: BPSFIDP_00526000005TbDtAAK
  return 'BPSFIDP_' + subParts[subParts.length - 1];
}

/**
 *
 * Decodes the JWT and returns a user
 * @returns IUser
 */
export function parseUserFromIdToken(encodedIdToken: string): IUser {
  const decodedToken: SFJWTPayLoad = jwtdecode<SFJWTPayLoad>(encodedIdToken, {
    header: false,
  });

  decodedToken.custom_attributes = decodedToken.custom_attributes || {};

  if (decodedToken.email !== undefined) {
    decodedToken.custom_attributes.email = decodedToken.email!;
  }

  const u: IUser = {
    userId: getUserIdFromSFToken(decodedToken),
    attributes: decodedToken.custom_attributes || {},
    firstName: decodedToken.given_name,
    lastName: decodedToken.family_name,
    phoneNumberVerified: decodedToken.custom_attributes['is-phone-verified'] === 'true',
    phone: decodedToken.custom_attributes['phone'],
  };
  return u;
}

/**
 *
 * Hashes user id and saves
 * @returns true if stored
 */
export function saveUserHashedId(id: string): boolean {
  try {
    const hashed = getHash(id);

    Persistence.setHashedObject(hashed);
    return true;
  } catch (error) {
    return false;
  }
}

/**
 *
 * Get hashed user id
 * @returns string
 */
export function getUserHashedId(): string | null {
  return Persistence.getHashedObject();
}

/**
 *
 * Checks if user id is same as stored user id (local stoarge)
 * @returns true if same
 */
export function isSameUser(id: string): boolean {
  try {
    const hashed = Persistence.getHashedObject();

    const currentUserIdHash = getHash(id);

    if (hashed) {
      return currentUserIdHash === hashed;
    }
    return false;
  } catch (error) {
    return false;
  }
}
